import Utilities from "./_utilities";
/* スクロール制御・慣性スクロール */
export default class ScrollCtl {
    constructor() {
        this.html = document.documentElement;
        this.currentWindowWidth = window.innerWidth;
        // footer制御用
        this.footerWrapper = document.getElementById('mainfooter');
        this.footer = document.querySelector('.l-mainfooter');
        if (this.footerWrapper && this.footer) {
            this.footerHeight = this.footerWrapper.getBoundingClientRect().height;
            this.footerTriggerPoint = this.footerWrapper.getBoundingClientRect().top + window.scrollY;
            this.footer.style.transform = 'translateY(' + (this.footerHeight * -0.5) + 'px)';
        }
    }
    static getInstance() {
        if (!this.lenis) {
            this.lenis = new ScrollCtl;
            this.lenis.activate();
        }
        return this.lenis;
    }
    activate() {
        const _this = this;
        let resizeTimer; // リサイズ挙動遅延用
        _this.lenisObj = new Lenis();
        function raf(time) {
            _this.lenisObj.raf(time);
            // スクロール方向
            if (_this.lenisObj.direction > 0) {
                // 下スクロール中
                _this.html.classList.add('is-scrolldown');
                _this.html.classList.remove('is-scrollup');
            }
            else if (_this.lenisObj.direction < 0) {
                // 上スクロール中
                _this.html.classList.add('is-scrollup');
                _this.html.classList.remove('is-scrolldown');
            }
            // コンテンツ上部かどうか
            if (_this.lenisObj.scroll > 180) {
                _this.html.classList.add('is-belowhead');
                _this.html.classList.remove('is-abovehead');
            }
            else {
                _this.html.classList.add('is-abovehead');
                _this.html.classList.remove('is-belowhead');
            }
            // footer制御用
            if (_this.footerWrapper && _this.footer) {
                _this.footerTriggerPoint = _this.footerWrapper.getBoundingClientRect().top + window.scrollY; // フッター位置更新
            }
            // タッチデバイスの場合は慣性スクロール非アクティブ
            if (!Utilities.isTouchDevice()) {
                if (_this.lenisObj.scroll + window.innerHeight >= _this.footerTriggerPoint) {
                    _this.footer.style.transform = 'translateY(' + (Math.floor((_this.footerHeight * -0.5) + ((_this.lenisObj.scroll + window.innerHeight) - _this.footerTriggerPoint) * 0.5) + 1) + 'px)';
                }
            }
            else {
                if (_this.footerWrapper && _this.footer) {
                    _this.footer.style.transform = 'translateY(0)';
                }
            }
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);
        // リサイズ挙動 高さ更新
        window.addEventListener('resize', (ev) => {
            if (this.currentWindowWidth !== window.innerWidth) {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    if (this.footerWrapper && this.footer) {
                        this.footerHeight = this.footerWrapper.getBoundingClientRect().height;
                        this.footerTriggerPoint = this.footerWrapper.getBoundingClientRect().top + window.scrollY;
                    }
                }, 200);
            }
        });
    }
    // スクロールロック時に解除
    lock() {
        this.lenisObj.stop();
    }
    // スクロールロック解除時にアクティブ
    unlock() {
        this.lenisObj.start();
    }
}
