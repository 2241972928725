export default class SidebarTransition {
    constructor() {
        // ブラウザバック時の遷移前状態キャッシュを除去
        window.addEventListener('pageshow', (ev) => {
            if (ev.persisted) {
                window.location.reload();
            }
        });
        this.sidenavLinks = document.querySelectorAll('.l-sidenav__link > li');
        this.transitionActivate();
    }
    transitionActivate() {
        this.sidenavLinks.forEach(elm => {
            elm.addEventListener('click', (ev) => {
                const trigger = ev.currentTarget;
                const url = trigger.querySelector('a').href;
                const target = trigger.querySelector('.l-sidenav__animelayer');
                const targetSize = { width: target.clientWidth, height: target.clientHeight };
                const targetScaleX = window.innerWidth / targetSize.width;
                const targetScaleY = (window.innerHeight / targetSize.height) + 0.1;
                ev.preventDefault();
                trigger.classList.add('is-transition');
                if (target) {
                    if (window.innerWidth < 1280) {
                        // 横配置時
                        anime.timeline({
                            targets: target,
                        }).add({
                            scaleX: targetScaleX,
                            easing: 'easeOutQuart',
                            duration: 600
                        }).add({
                            scaleY: targetScaleY,
                            easing: 'easeInOutQuart',
                            duration: 800,
                            complete: () => {
                                location.href = url;
                            }
                        });
                    }
                    else {
                        anime.timeline({
                            targets: target,
                        }).add({
                            scaleY: window.innerHeight / (targetSize.height * 0.9),
                            easing: 'easeOutQuart',
                            duration: 800
                        }).add({
                            scaleX: window.innerWidth / targetSize.width,
                            easing: 'easeInOutQuart',
                            duration: 800,
                            complete: () => {
                                location.href = url;
                            }
                        });
                    }
                    anime({
                        targets: '.l-mainheader__logo, .l-mainheader__navtoggle, .l-mainheader__gnav',
                        opacity: 0,
                        easing: 'linear',
                        duration: 200
                    });
                }
            });
        });
    }
}
