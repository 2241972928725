import BackgroundScrollLock from "./_background-scroll-lock";
/* UI制御 */
export default class CommonUI {
    constructor() {
        this.scrollLock = BackgroundScrollLock.getInstance();
        this.mainHeader = document.querySelector('.l-mainheader'); // メインヘッダー
        this.navToggle = document.querySelector('.l-mainheader__navtoggle'); // ナビトグルボタン
        this.navWrapper = document.querySelector('.l-mainnav__nav'); // メインナビ
        if (this.mainHeader && this.navWrapper) {
            this.navHasSub = this.navWrapper.querySelectorAll('.has-subnav'); // 開閉リンク
            this.navBack = this.navWrapper.querySelector('.l-mainnav__back'); // ドリルアップ（戻る）ボタン
            // this.navPickup = document.querySelector('.l-mainnav__pickup'); // ピックアップ
            this.mainFunc = {
                // メインナビ制御
                navToggleHandler: (ev) => {
                    ev.preventDefault();
                    this.mainHeader.classList.toggle('is-nav-active');
                    if (this.mainHeader.classList.contains('is-nav-active')) {
                        this.scrollLock.lock();
                    }
                    else {
                        this.scrollLock.unlock();
                    }
                },
                // 開閉
                listToggle: (ev) => {
                    const trigger = ev.currentTarget;
                    ev.preventDefault();
                    this.navListToggle(trigger);
                },
                // ドリルアップ（戻る）
                navDrillup: (ev) => {
                    const current = document.querySelector('.l-mainnav__nav .is-current'); // 現在
                    const entryClass = current.dataset.navParent;
                    const entryElm = document.querySelector('.l-mainnav__nav .is-entry [data-nav-sub="' + entryClass + '"]'); // 開閉元
                    ev.preventDefault();
                    this.navListToggle(entryElm);
                }
            };
            this.init();
        }
    }
    init() {
        this.destroy(); // reset
        this.navToggle.addEventListener('click', this.mainFunc.navToggleHandler); // メインナビ
        this.navHasSub.forEach(elm => {
            elm.addEventListener('click', this.mainFunc.listToggle); // 開閉
        });
        this.navBack.addEventListener('click', this.mainFunc.navDrillup); // ドリルアップ（戻る）
        this.pickupSlider();
    }
    // ナビ開閉制御
    navListToggle(_trigger) {
        const trigger = _trigger; // 開閉元
        const parent = trigger.parentElement; // 開閉元リスト
        // 対象を
        if (!trigger.classList.contains('is-active')) {
            // 兄弟が開いていたら閉じる
            for (const child of parent.children) {
                if (child.classList.contains('is-active')) {
                    this.navClose(child);
                }
            }
            ;
            // 開く
            this.navOpen(trigger);
        }
        else {
            // 閉じる
            this.navClose(trigger);
        }
        // ナビ開閉状態を上位レイヤーに通知
        this.navWrapper.dataset.currentNav = this.navWrapper.querySelector('.is-current').classList.toString();
    }
    // 対象ナビを開く
    navOpen(_trigger) {
        const trigger = _trigger; // 開閉元
        const parent = trigger.parentElement; // 開閉元リスト
        const targetClass = trigger.dataset.navSub;
        const targetElm = document.querySelector('.l-mainnav__nav [data-nav-parent="' + targetClass + '"]'); // 開閉先リスト
        const wrapper = targetElm.parentElement; // 開閉先リストカラム
        if (targetClass && targetElm) {
            parent.classList.remove('is-current');
            parent.classList.add('is-entry');
            targetElm.classList.add('is-current');
            wrapper.classList.add('is-active');
            trigger.classList.add('is-active');
        }
    }
    // 対象ナビを閉じる
    navClose(_trigger) {
        const trigger = _trigger; // 開閉元
        const parent = trigger.parentElement; // 開閉元リスト
        const targetClass = trigger.dataset.navSub;
        const targetElm = document.querySelector('.l-mainnav__nav [data-nav-parent="' + targetClass + '"]'); // 開閉先リスト
        const wrapper = targetElm.parentElement; // 開閉先リストカラム
        if (targetClass && targetElm) {
            // まず孫を閉じる
            for (const child of targetElm.children) {
                if (child.classList.contains('is-active')) {
                    this.navClose(child);
                }
            }
            ;
            parent.classList.add('is-current');
            parent.classList.remove('is-entry');
            targetElm.classList.remove('is-current');
            wrapper.classList.remove('is-active');
            trigger.classList.remove('is-active');
        }
    }
    pickupSlider() {
        const navSwiper = new Swiper('.l-mainnav__pickup__slider', {
            spaceBetween: 20,
            slidesPerView: 1.2,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                768: {
                    spaceBetween: 40,
                    slidesPerView: 2
                }
            }
        });
    }
    destroy() {
        this.navToggle.removeEventListener('click', this.mainFunc.navToggleHandler); // メインナビ
        this.navHasSub.forEach(elm => {
            elm.removeEventListener('click', this.mainFunc.listToggle); // 開閉
        });
        this.navBack.removeEventListener('click', this.mainFunc.navDrillup); // ドリルアップ（戻る）
        document.querySelectorAll('.l-mainnav__nav .is-active').forEach(elm => {
            elm.classList.remove('is-active');
        });
        document.querySelectorAll('.l-mainnav__nav .is-entry').forEach(elm => {
            elm.classList.remove('is-entry');
        });
        document.querySelectorAll('.l-mainnav__nav .is-current').forEach(elm => {
            elm.classList.remove('is-current');
        });
        document.querySelector('.l-mainnav__lv2').classList.add('is-current');
        this.mainHeader.classList.remove('is-nav-active');
        this.scrollLock.unlock();
    }
}
