/* ページの頭に戻る */
export default class Backhead {
    constructor() {
        this.backhead = document.querySelector('.l-mainfooter__backhead button'); //as HTMLElement;
        if (this.backhead) {
            this.init();
        }
    }
    init() {
        var _a;
        (_a = this.backhead) === null || _a === void 0 ? void 0 : _a.addEventListener('click', (ev) => {
            ev.preventDefault();
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        });
    }
}
