/* ローカルナビ */
export default class Localnav {
    constructor() {
        this.gnavItem = document.querySelectorAll('.l-mainheader__gnav > li > a');
        this.primaryItem = document.querySelectorAll('.js-localnav-primary-item > a');
        this.secondaryItem = document.querySelectorAll('.js-localnav-secondary-item > a');
        this.currentURL = '/';
    }
    init() {
        const _this = this;
        this.currentURL = this.normalizeURL(location.href);
        if (this.currentURL === '/')
            return;
        // 第2階層 カテゴリー名完全一致
        this.gnavItem.forEach((_elm) => {
            const elm = _elm;
            const category = elm.getAttribute('href').replace(/\//g, '');
            const currentCate = _this.currentURL.split('/')[1];
            if (category && currentCate === category) {
                elm.classList.add('is-current');
            }
        });
        // 第3階層は前方一致
        this.primaryItem.forEach((_elm) => {
            const elm = _elm;
            const url = _this.normalizeURL(elm.getAttribute('href'));
            const regexp = new RegExp('^' + url);
            if (url !== '/solution/') {
                if ((url && _this.currentURL === url) || (url && _this.currentURL.match(regexp))) {
                    elm.classList.add('is-current');
                }
            }
        });
        // 第4階層は後方一致
        this.secondaryItem.forEach((_elm) => {
            const elm = _elm;
            const url = _this.normalizeURL(elm.getAttribute('href'));
            const regexp = new RegExp(url + '$');
            if ((url && _this.currentURL === url) || (url && _this.currentURL.match(regexp))) {
                elm.classList.add('is-current');
            }
        });
    }
    normalizeURL(url) {
        if (url === void 0)
            return;
        if (url.indexOf(location.host) !== -1) {
            return url.replace(location.protocol + '//', '').replace(location.host, '').replace(':' + location.port, '').replace(/index\.(html|php)/, '').replace(/\?(.*)$/, '').replace(/#(.*)$/, '');
        }
        else {
            return url.replace(/index\.(html|php)/, '').replace(/\?(.*)$/, '').replace(/#(.*)$/, '');
        }
    }
}
