/* フローティングバナー表示 */
export default class BannerControl {
    constructor() {
        this.bannerReport = document.querySelector('.l-banner-report');
        this.bannerReportClose = this.bannerReport.querySelector('.l-banner-report__close');
        if (this.bannerReport) {
            this.init();
        }
    }
    init() {
        const isHidden = sessionStorage.getItem('bannerReport');
        if (!isHidden) {
            this.bannerReport.classList.remove('is-hidden');
            this.bannerReportClose.addEventListener('click', (ev) => {
                sessionStorage.setItem('bannerReport', 'hidden');
                this.bannerReport.classList.add('is-hidden');
            });
        }
        else {
            if (isHidden === 'hidden') {
                this.bannerReport.classList.add('is-hidden');
            }
        }
    }
}
