import CommonUI from "./_ui";
import SidebarTransition from "./_sidebar-transition";
import Localnav from "./_localnav";
import Backhead from "./_backhead";
import Utilities from "./_utilities";
import Modal from "./_modal";
// import CookieConsent from "./_cookie-consent";
import BannerControl from "./_banner-control";
const commonUI = new CommonUI;
const sidebarTransition = new SidebarTransition;
const localnav = new Localnav();
const backhead = new Backhead();
const modal = new Modal;
// const cookieConsent = new CookieConsent;
const bannerControl = new BannerControl;
// カテゴリートップ判定
if (document.querySelector('.l-content').classList.contains('l-content--cattop')) {
    document.querySelector('html').classList.add('is-cattop');
}
// iOSのbackground-attachementバグ対策
if (Utilities.deviceInspector().isIOS || Utilities.deviceInspector().isIPadOS) {
    document.querySelector('html').classList.add('is-iOS');
}
localnav.init();
