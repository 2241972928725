/* モーダル */
import BackgroundScrollLock from "./_background-scroll-lock";
export default class Modal {
    constructor() {
        this.scrollLock = BackgroundScrollLock.getInstance();
        this.triggers = document.querySelectorAll('a[href^="#"].js-modal-link');
        this.modals = document.querySelectorAll('.js-modal');
        this.closeButton = document.querySelectorAll('.js-modal-close');
        // モーダル
        this.modals.forEach((item) => {
            item.addEventListener('wheel', (ev) => {
                ev.stopPropagation();
            });
            item.addEventListener('touchmove', (ev) => {
                ev.stopPropagation();
            });
        });
        // closeボタン
        this.closeButton.forEach((item) => {
            item.addEventListener('click', (ev) => {
                const elm = ev.currentTarget;
                const targetModal = elm.closest('.js-modal');
                ev.preventDefault();
                targetModal.classList.remove('is-active');
                this.scrollLock.unlock();
            });
        });
        this.activate();
    }
    activate() {
        const _this = this;
        this.triggers.forEach((item) => {
            item.addEventListener('click', (ev) => {
                const elm = ev.currentTarget;
                const hash = elm.getAttribute('href').replace('#', '');
                const targetModal = document.getElementById(hash);
                ev.preventDefault();
                targetModal.classList.add('is-active');
                _this.scrollLock.lock();
            });
        });
    }
}
