/* 背景スクロールロック */
import ScrollCtl from "./_scroll-control";
export default class BackgroundScrollLock {
    constructor() {
        this.scrollCtl = ScrollCtl.getInstance();
        this.html = document.querySelector('html');
    }
    static getInstance() {
        if (!this.backgroundScrollLock) {
            this.backgroundScrollLock = new BackgroundScrollLock;
        }
        return this.backgroundScrollLock;
    }
    lock() {
        if (!this.html.classList.contains('locked')) {
            // スクロールバー検知（がたつき防止）
            if (this.html.clientWidth < window.innerWidth) {
                this.html.classList.add('has-scrollbar');
            }
            this.html.classList.add('locked');
            this.scrollCtl.lock();
        }
    }
    unlock() {
        if (this.html.classList.contains('locked')) {
            this.html.classList.remove('locked');
            this.html.classList.remove('has-scrollbar');
            this.scrollCtl.unlock();
        }
    }
}
